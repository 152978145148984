/* #1F497D */
/* #81c784 */
/* #50C2F7 */
section-text > br {
	display: block;
	content: '';
	border-bottom: 25px solid transparent;
}
@-moz-document url-prefix() {
	br {
		margin-bottom: 25px;
	}
}

.main-container {
	margin: 0 25px;
	padding: 25px 0;
}

.header-container {
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.body-container {
	padding: 50px 0;
}

.section-container {
	margin: 25px 0 0 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 25px;
}

.section-header {
	padding-top: 25px;
}

.section-text {
	text-align: justify;
	margin-left: 25px;
}

.first-section {
	color: #1c6fd7;
}

.second-section {
	color: #81c784;
}

.third-section {
	color: #50c2f7;
}

.fourth-section {
	color: #8e3ef1;
}

img {
	align-self: center;
}

ul,
ol,
li {
	margin: 25px 0;
}

@media only screen and (max-width: 425px) {
	.header-container {
		margin-top: 25px;
		flex-direction: column;
		row-gap: 50px;
		justify-content: center;
	}

	.section-container {
		margin: 12.5px 0 0 12.5px;
		row-gap: 12.5px;
	}

	.section-text {
		margin-left: 12.5px;
	}

	.main-container img {
		width: 100%;
		margin: 12.5px 0;
	}

	.main-container ul,
	.main-container ol,
	.main-container li {
		margin: 12.5px 0;
	}
}
