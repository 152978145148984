.spinner {
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
	border-radius: 50%;
	border-top: 3px solid white;
	height: 50px;
	width: 50px;
	animation-name: spin-spinner;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
}

@keyframes spin-spinner {
	from {
		rotate: 0deg;
	}
	to {
		rotate: calc(360deg * 3);
	}
}
