.login {
	margin: 0;
	width: 100%;
	background-color: #221f20;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 50px;
}

.login-content {
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	width: 100%;
	max-width: 350px;
	align-items: stretch;
}

.login-coin {
	height: 100px;
	animation: flip-coin 3s ease-in-out infinite,
		move-coin 3s ease-in-out infinite;
}

@keyframes flip-coin {
	0%,
	25% {
		transform: rotateX(0deg);
	}
	45% {
		transform: rotateX(-45deg);
	}
	50%,
	70%,
	90% {
		transform: rotateX(180deg);
	}
	60%,
	80%,
	100% {
		transform: rotateX(0deg);
	}
}

@keyframes move-coin {
	45% {
		translate: 0 0;
	}
	50% {
		translate: 0 -200%;
	}
	90% {
		translate: 0 -200%;
	}
	100% {
		translate: 0 0;
	}
}
