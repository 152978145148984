.home-container {
	background-color: #f1f1f2;
	height: 100vh;
}

.home {
	margin: auto;
	padding-top: 75px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 50px;
}

.buttons-container {
	display: flex;
	column-gap: 50px;
	justify-content: center;
	text-decoration: none;
}

.button {
	white-space: break-spaces;
	height: 75px;
	width: 150px;
}
